<template>
  <svg :width="width || 32" :height="height || 32" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.6127 14.8919L14.1756 10.2126C13.9394 10.0414 13.6258 10.0156 13.3669 10.1487C13.1059 10.2807 12.9429 10.5489 12.9429 10.8388V20.1942C12.9429 20.4872 13.1059 20.7543 13.3669 20.8864C13.4772 20.9421 13.5979 20.9699 13.7197 20.9699C13.8785 20.9699 14.0394 20.9194 14.1756 20.8193L20.6127 16.1442C20.8159 15.9946 20.9345 15.7636 20.9345 15.518C20.9356 15.2684 20.8138 15.0384 20.6127 14.8919Z" :fill="fill || '#305BF3'"/>
    <path d="M15.5005 0C6.9384 0 0 6.9384 0 15.5005C0 24.0595 6.9384 30.9959 15.5005 30.9959C24.0606 30.9959 31 24.0585 31 15.5005C31.001 6.9384 24.0606 0 15.5005 0ZM15.5005 28.4097C8.37024 28.4097 2.58926 22.6318 2.58926 15.5005C2.58926 8.3723 8.37024 2.5872 15.5005 2.5872C22.6298 2.5872 28.4097 8.37127 28.4097 15.5005C28.4107 22.6318 22.6298 28.4097 15.5005 28.4097Z" :fill="fill || '#305BF3'"/>
  </svg>
</template>

<script>
import icon_config from "@/mixins/icon_config";

export default {
  name: "IconPlayCircle",
  mixins: [icon_config],
}
</script>

<style scoped>

</style>
