import routerList from '@/router/labels'
import i18n from '@/i18n'
import store from '@/store'
import { getFromLocalStorage } from '@/mixins/local_storage'

export const mainPageMenuList = {
  namespaced: true,
  state: {
    administrator: {
      office: getFromLocalStorage('office', true),
      list: [
        {
          id: 2,
          alias: 'vacancy',
          title: i18n.tc('index.menuList.vacancies.root.title'),
          subTitle: i18n.tc('index.menuList.vacancies.root.text'),
          link: routerList.VACANCIES_LIST_ROUTE_PATH,
          linkName: routerList.VACANCIES_LIST_NAME,
          level: 1
        },
        {
          id: 1,
          alias: 'office',
          title: i18n.tc('index.menuList.object.title'),
          subTitle: i18n.tc('index.menuList.object.text'),
          link: routerList.OFFICE_LIST_ROUTE_PATH,
          linkName: routerList.OFFICE_LIST_ROUTE_NAME,
          level: 1
        },
        {
          id: 0,
          alias: 'user',
          title: i18n.tc('index.menuList.user.title'),
          subTitle: i18n.tc('index.menuList.user.text'),
          link: routerList.USER_LIST_PATH,
          linkName: routerList.USER_LIST_NAME,
          level: 1
        },
        {
          id: 5,
          alias: 'amount',
          title: i18n.tc('index.menuList.balance.title'),
          subTitle: i18n.tc('index.menuList.balance.text'),
          offerLessTitle: i18n.tc('index.menuList.offerLessBalance.title'),
          offerLessSubTitle: i18n.tc('index.menuList.offerLessBalance.text'),
          link: routerList.BALANCE_ROUTE_PATH,
          linkName: routerList.BALANCE_NAME,
          level: 1
        },
        {
          id: 0,
          alias: 'offer',
          title: i18n.tc('index.menuList.offer.title'),
          subTitle: i18n.tc('index.menuList.offer.text'),
          link: routerList.OFFICE_OFFER_LIST_ROUTE_PATH,
          linkName: routerList.OFFICE_OFFER_LIST_NAME,
          level: 2
        },
        {
          id: 1,
          alias: 'shift',
          title: i18n.tc('index.menuList.shift.title'),
          subTitle: i18n.tc('index.menuList.shift.text'),
          link: routerList.OFFICE_SHIFT_LIST_ROUTE_PATH,
          linkName: routerList.OFFICE_SHIFT_LIST_NAME,
          level: 2
        },
        // {
        //   id: 3,
        //   alias: 'document',
        //   title: i18n.tc('index.menuList.documents.title'),
        //   subTitle: i18n.tc('index.menuList.documents.text'),
        //   link: routerList.OFFICE_DOCUMENTS_ROUTE_PATH,
        //   linkName: routerList.OFFICE_DOCUMENTS_NAME
        // },
        // {
        //   id: 4,
        //   alias: 'organizationProfile',
        //   title: i18n.tc('index.menuList.requisites.title'),
        //   subTitle: i18n.tc('index.menuList.requisites.text'),
        //   link: routerList.OFFICE_COMPANY_PROFILE_ROUTE_PATH,
        //   linkName: routerList.OFFICE_COMPANY_PROFILE_NAME
        // },

        // {
        //   id: 6,
        //   alias: 'support',
        //   title: i18n.tc('index.menuList.faq.title'),
        //   subTitle: i18n.tc('index.menuList.faq.text'),
        //   link: routerList.USER_SUPPORT_ROUTE_PATH,
        //   linkName: routerList.USER_SUPPORT_ROUTE_NAME
        // }
      ]
    },
    admin: {
      office: store.state.office.userOffice,
      list: [
        // {
        //   id: 2,
        //   alias: 'vacancy',
        //   title: i18n.tc('index.menuList.vacancies.manager.title'),
        //   subTitle: i18n.tc('index.menuList.vacancies.manager.text'),
        //   link: routerList.VACANCIES_LIST_ROUTE_PATH,
        //   linkName: routerList.VACANCIES_LIST_NAME
        // },
        {
          id: 1,
          alias: 'office',
          title: i18n.tc('index.menuList.object.title'),
          subTitle: i18n.tc('index.menuList.object.text'),
          link: routerList.OFFICE_LIST_ROUTE_PATH,
          linkName: routerList.OFFICE_LIST_ROUTE_NAME,
          level: 1
        },
        {
          id: 0,
          alias: 'user',
          title: i18n.tc('index.menuList.user.title'),
          subTitle: i18n.tc('index.menuList.user.text'),
          link: routerList.USER_LIST_PATH,
          linkName: routerList.USER_LIST_NAME,
          level: 1
        },
        {
          id: 3,
          alias: 'amount',
          title: i18n.tc('index.menuList.balanceManager.title'),
          subTitle: i18n.tc('index.menuList.balanceManager.text'),
          offerLessTitle: i18n.tc('index.menuList.offerLessBalanceManager.title'),
          offerLessSubTitle: i18n.tc('index.menuList.offerLessBalanceManager.text'),
          link: routerList.BALANCE_ROUTE_PATH,
          linkName: routerList.BALANCE_NAME,
          level: 1
        },
        {
          id: 0,
          alias: 'offer',
          title: i18n.tc('index.menuList.offer.title'),
          subTitle: i18n.tc('index.menuList.offer.text'),
          link: routerList.OFFICE_OFFER_LIST_ROUTE_PATH,
          linkName: routerList.OFFICE_OFFER_LIST_NAME,
          level: 2
        },
        {
          id: 1,
          alias: 'shift',
          title: i18n.tc('index.menuList.shift.title'),
          subTitle: i18n.tc('index.menuList.shift.text'),
          link: routerList.OFFICE_SHIFT_LIST_ROUTE_PATH,
          linkName: routerList.OFFICE_SHIFT_LIST_NAME,
          level: 2
        },
        // {
        //   id: 4,
        //   alias: 'amount',
        //   title: i18n.tc('index.menuList.info.title'),
        //   subTitle: i18n.tc('index.menuList.info.text'),
        //   link: routerList.OFFICE_PAYMENT_LIST_ROUTE_PATH,
        //   linkName: routerList.OFFICE_PAYMENT_LIST_NAME
        // },
        // {
        //   id: 5,
        //   alias: 'faq',
        //   title: i18n.tc('index.menuList.faq.title'),
        //   subTitle: i18n.tc('index.menuList.faq.text'),
        //   link: routerList.USER_SUPPORT_ROUTE_PATH,
        //   linkName: routerList.USER_SUPPORT_ROUTE_NAME
        // }
      ]
    },
    manager: {
      office: store.state.office.userOffice,
      list: [
        // {
        //   id: 2,
        //   alias: 'vacancy',
        //   title: i18n.tc('index.menuList.vacancies.manager.title'),
        //   subTitle: i18n.tc('index.menuList.vacancies.manager.text'),
        //   link: routerList.VACANCIES_LIST_ROUTE_PATH,
        //   linkName: routerList.VACANCIES_LIST_NAME
        // },
        {
          id: 3,
          alias: 'amount',
          title: i18n.tc('index.menuList.balanceManager.title'),
          subTitle: i18n.tc('index.menuList.balanceManager.text'),
          offerLessTitle: i18n.tc('index.menuList.offerLessBalanceManager.title'),
          offerLessSubTitle: i18n.tc('index.menuList.offerLessBalanceManager.text'),
          link: routerList.BALANCE_ROUTE_PATH,
          linkName: routerList.BALANCE_NAME,
          level: 1
        },
        {
          id: 0,
          alias: 'offer',
          title: i18n.tc('index.menuList.offer.title'),
          subTitle: i18n.tc('index.menuList.offer.text'),
          link: routerList.OFFICE_OFFER_LIST_ROUTE_PATH,
          linkName: routerList.OFFICE_OFFER_LIST_NAME,
          level: 2
        },
        {
          id: 1,
          alias: 'shift',
          title: i18n.tc('index.menuList.shift.title'),
          subTitle: i18n.tc('index.menuList.shift.text'),
          link: routerList.OFFICE_SHIFT_LIST_ROUTE_PATH,
          linkName: routerList.OFFICE_SHIFT_LIST_NAME,
          level: 2
        },
        // {
        //   id: 4,
        //   alias: 'amount',
        //   title: i18n.tc('index.menuList.info.title'),
        //   subTitle: i18n.tc('index.menuList.info.text'),
        //   link: routerList.OFFICE_PAYMENT_LIST_ROUTE_PATH,
        //   linkName: routerList.OFFICE_PAYMENT_LIST_NAME
        // },
        // {
        //   id: 5,
        //   alias: 'faq',
        //   title: i18n.tc('index.menuList.faq.title'),
        //   subTitle: i18n.tc('index.menuList.faq.text'),
        //   link: routerList.USER_SUPPORT_ROUTE_PATH,
        //   linkName: routerList.USER_SUPPORT_ROUTE_NAME
        // }
      ]
    },
  }
}
