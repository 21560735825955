<template>
  <div>
    <div style="margin: 0; padding: 0">
      <div
        class="index-list__title"
        @click="$emit('onInitRoutePush', model, office, role)"
      >
        <span style="text-decoration: underline">
          {{ `${myOrganizationIsOfferLess && model.offerLessTitle ?
            model.offerLessTitle : model.title}` }}
        </span>
        <span
          style="display: inline-block;  vertical-align: top;color: #004499 !important;
           font-weight: lighter; font-size: 0.8em; margin-left: 16px; margin-right: 3px"
          @click.stop="showVideo(model.alias)"
        >
          {{ `Видеоинструкция` }}</span>
        <div
          style="display: inline-block; vertical-align: top; margin: 0; padding: 0"
          @click="showVideo(model.alias)"
        >
          <IconPlay
            width="20"
            height="20"
          />
        </div>
      </div>
      <div class="index-list__text">
        {{ myOrganizationIsOfferLess && model.offerLessSubTitle ?
          model.offerLessSubTitle : model.subTitle }}
      </div>
    </div>
  </div>
</template>

<script>
import IconPlay from '@/components/common/icons/IconPlayCircle'

export default {
  name: 'HelpItem',
  components: { IconPlay, },
  props: {
    model: {
      type: Object,
    },
    office: {
      type: Object,
    },
    role: {
      type: String,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    showVideo (step) {
      this.$store.commit('popup/currentStep', step)
      this.$store.dispatch('popup/showModal', { key: 'showVideoModal' })
    },
  }
}
</script>

<style scoped lang="sass">
.index-list
  width: 100%
  display: flex
  justify-content: space-between
  flex-wrap: wrap

  &__item
    width: 100%
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    min-height: 45px
    background: #FFFFFF
    border: 1.5px solid #E3E3E3
    box-sizing: border-box
    box-shadow: 0 5px 15px rgba(117, 117, 117, 0.1)
    border-radius: 6px
    padding: 8px
    cursor: pointer
    margin-bottom: 16px

  &__title
    font-style: normal
    font-weight: 800
    font-size: 16px
    line-height: 20px
    opacity: 0.9
    color: #8BD118
  //border-bottom: 1px solid #e5e5e5
  &__text
    font-weight: 500
    font-size: 12px
    line-height: 18px
    color: #666
    margin-top: 8px

</style>
